import React, { useEffect, useState, useRef } from "react";
import {
    Row,
    Col,
    Card,
    Table,
    Button,
    Form,
    Tooltip,
    Input,
    Popconfirm, Upload,
} from "antd";
import {
    UnorderedListOutlined,
    EditFilled,
    DeleteFilled,
} from "@ant-design/icons";
import { convertColumns } from "../../../utils/columnconverter";
import { notify } from "../../../redux/actions";
import { connect } from "react-redux";
import { agros } from "../../../const/api";
import { useTranslation } from "react-i18next";
import { noWhitespace } from "../../../utils/rules";
import JoditEditor from "jodit-react";
import { whiteSpace } from "../../../utils/rules";
const Products = (props) => {
    const editor = useRef()
    const mainUrl = "root/MainPageContent"
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [fileList, setFileList] = useState([])
    const [text, setText] = useState({
        name_az: '',
        name_en: ''
    })
    const [previewVisible, setPreviewVisible] = useState(false)
    const [previewImage, setPreviewImage] = useState([])
    const [file, setFile] = useState()
    const [isActive, setActive] = useState(false);
    const [image, setImage] = useState({})
    const [positions, setPositions] = useState([]);
    const [spin, setSpin] = useState(false);
    const [editing, setEditing] = useState(null);
    const [langs, setLangs] = useState([]);
    const cols = [
        { key: "index", value: "#", con: true },
        { key: "imageUrl", value: 'Şəkil', con: true },
        { key: "id", value: "", con: false },
    ];
    const nameInput = useRef();
    const { notify } = props;

    const columns = [
        {
            title: "#",
            key: "1",
            dataIndex: "index",
            width: 80,
        },
        {
            title: "Ad",
            dataIndex: "title",
            key: "5",
        },
        {
            title: "",
            key: "3",
            dataIndex: "id",
            width: 30,
            render: (i) => {
                return (
                    <div className="flex flex-end">
                        <Popconfirm
                            placement="topRight"
                            title={t("areYouSure")}
                            onConfirm={() => deletePosition(i)}
                            okText={t("yes")}
                            cancelText={t("no")}
                        >
                            <Tooltip className="ml-5" title={t("delete")}>
                                <Button className="border-none" type="text" shape="circle">
                                    <DeleteFilled />
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                        <Tooltip className="ml-5" title={t("edit")} placement="topRight">
                            <Button
                                className="border-none"
                                type="text"
                                shape="circle"
                                onClick={() => setEditingObject(i)}
                            >
                                <EditFilled />
                            </Button>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    const getLanguage = async () => {
        await agros.get('languages').then((res) => {
            setLangs(res.data)
        });
    }

    const setEditingObject = async (i) => {
        setEditing(i);
        await agros.get(`${mainUrl}/${i}`).then((res) => {
            let obj = {};
            // let objText = {};
            let datas = {}
            res.data.languageContent.forEach((item) => {
                // obj[`body_${item.language}`] = item.content; // body text ola biler
                // objText[`text_${item.language}`] = item.content;
                obj[`name_${item.language}`] = item.title;
                obj[`body_${item.language}`] = item.description;
                datas[`body_${item.language}`] = item.description;
            });
            setText(datas)
            // setActive(names.is_active === 1)
            form.setFieldsValue(obj);
        });
    };

    const cancelEditing = () => {
        setEditing(null);
        setActive(false)
        setText({})
        form.resetFields();
    };

    const deletePosition = async (i) => {
        await agros
            .delete(`${mainUrl}/${i}`)
            .then(() => {
                // description
                notify("silindi", true);
                getPositions();
            })
            .catch((err) => {
                //error
                notify(err.response, false);
            });
    };

    const savePosition = async (values) => {
        let obj = {
            LanguageContent: langs.map((l) => {
                return {
                    Language: l.code,
                    Description: values[`body_${l.code}`],
                    Title: values[`name_${l.code}`],
                };
            }),
        };
        if (!editing) {
            await agros
                .post(mainUrl, obj)
                .then((res) => {
                    notify("", true);
                    getPositions();
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        } else {
            obj["id"] = editing;
            await agros
                .put(`${mainUrl}/${editing}`, obj)
                .then((res) => {
                    notify("", true);
                    getPositions();
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }
    };

    const getPositions = async () => {
        setSpin(true);
        await agros.get(mainUrl, { params: { per_page: 20000 } }).then((res) => {
            setSpin(false);
            setPositions(
                res.data.map((p, index) => {
                    return {
                        key: index + 1,
                        ...p,
                        index: index + 1,
                        // name: p.locales.find((l) => {
                        //     return l.local === localStorage.getItem("locale");
                        // }).name,
                    };
                })
            );
        });
    };


    useEffect(() => {
        getPositions();
        getLanguage();
    }, [t]);

    return (
        <Row gutter={[10, 10]}>
            <Col xs={24}>
                <div className="border animated fadeInDown p-2 mt-0 bg-white">
                    <UnorderedListOutlined className="f-20 mr5-15" />
                    <span className="f-20 bold">Ana Səhifə</span>
                </div>
            </Col>
            <Col lg={12} xs={24}>
                <Table
                    loading={spin}
                    size="small"
                    className="bg-white animated fadeInLeft"
                    columns={columns}
                    dataSource={convertColumns(positions, cols)}
                    pagination={{
                        pageSize: 10,
                        current_page: 1,
                        total: positions.length,
                    }}
                />
            </Col>
            <Col lg={12} xs={24}>
                <Card title={t("addTo")} className={"animated fadeInRight"}>
                    <Form layout="vertical" onFinish={savePosition} form={form}>
                        <p className="mb-5">Slayder</p>

                        <Col xs={24}>
                            {/*<div className="mb-10">Kontent</div>*/}
                            {langs.map((l) => (
                                <div key={l.id} className="form-lang">
                                    <div className="mb-10">
                                        {l.name}
                                    </div>
                                    <div className="form-lang">
                                    <Form.Item
                                        className="mb-5"
                                        validateTrigger="onChange"
                                        name={`name_${l.code}`}
                                        rules={[whiteSpace(t("inputError"))]}
                                    >
                                        <Input ref={nameInput} />
                                    </Form.Item>
                                    <div className="input-lang">{l.code}</div>
                                    </div>
                                    <Form.Item
                                        name={`body_${l.code}`}
                                        validateTrigger="onChange"
                                        // getValueFromEvent={(event, editor) => {
                                        //     const dat = editor.getData();
                                        //     return dat;
                                        // }}
                                        rules={[noWhitespace(t("inputError"))]}
                                    >
                                        <JoditEditor
                                            onChange={(newContent) => {
                                                form.setFieldsValue({
                                                    [`text_${l.key}`]: newContent
                                                })
                                            }}
                                            className={'mb-15'}
                                            ref={editor}
                                            value={text[`text_${l.key}`]}
                                            tabIndex={1} // tabIndex of textarea
                                        />

                                    </Form.Item>
                                </div>
                            ))}
                        </Col>


                        <div className="flex  flex-between mt-15">
                            <Button onClick={cancelEditing}>{t("cancel")}</Button>
                            <Button htmlType="submit">{t("save")}</Button>
                        </div>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
};

const mapStateToProps = ({ langs }) => {
    return {
        langs: langs,
    };
};

export default connect(mapStateToProps, { notify })(Products);