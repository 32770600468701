import React, { useEffect, useState, useRef } from "react";
import {
    Row,
    Col,
    Card,
    Table,
    Button,
    Form,
    Tooltip,
    Input,
    Popconfirm, Upload,
} from "antd";
import {
    UnorderedListOutlined,
    EditFilled,
    DeleteFilled,
} from "@ant-design/icons";
import { convertColumns } from "../../../utils/columnconverter";
import { notify } from "../../../redux/actions";
import { connect } from "react-redux";
import { agros } from "../../../const/api";
import { useTranslation } from "react-i18next";
import { noWhitespace } from "../../../utils/rules";
import JoditEditor from "jodit-react";
import { whiteSpace } from "../../../utils/rules";
const History = (props) => {
    const editor = useRef()
    const mainUrl = "root/OurHistory"
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [fileList, setFileList] = useState([])
    const [text, setText] = useState({
        name_az: '',
        name_en: ''
    })
    const [previewVisible, setPreviewVisible] = useState(false)
    const [previewImage, setPreviewImage] = useState([])
    const [file, setFile] = useState()
    const [isActive, setActive] = useState(false);
    const [image, setImage] = useState({})
    const [positions, setPositions] = useState([]);
    const [spin, setSpin] = useState(false);
    const [editing, setEditing] = useState(null);
    const [langs, setLangs] = useState([]);
    const cols = [
        { key: "index", value: "#", con: true },
        { key: "imageUrl", value: 'Şəkil', con: true },
        { key: "id", value: "", con: false },
    ];
    const nameInput = useRef();
    const { notify } = props;

    const columns = [
        {
            title: "#",
            key: "1",
            dataIndex: "index",
            width: 80,
        },
        {
            title: "Şəkil",
            dataIndex: "imageUrl",
            key: "9",
            render: (i) => {
                return <img className={"tableImage"} src={i} alt="" />;
            },
        },
        {
            title: "",
            key: "3",
            dataIndex: "id",
            width: 30,
            render: (i) => {
                return (
                    <div className="flex flex-end">
                        <Popconfirm
                            placement="topRight"
                            title={t("areYouSure")}
                            onConfirm={() => deletePosition(i)}
                            okText={t("yes")}
                            cancelText={t("no")}
                        >
                            <Tooltip className="ml-5" title={t("delete")}>
                                <Button className="border-none" type="text" shape="circle">
                                    <DeleteFilled />
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                        <Tooltip className="ml-5" title={t("edit")} placement="topRight">
                            <Button
                                className="border-none"
                                type="text"
                                shape="circle"
                                onClick={() => setEditingObject(i)}
                            >
                                <EditFilled />
                            </Button>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    
    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList)
        if (newFileList.length <= 0) {
            setFile(null)
            setImage(null)
        }
    };


    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    const handlePreview = async (file) => {

        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview)
        setPreviewVisible(true)
    };

    const setUploadFile = ({ onSuccess, onError, file }) => {
        let form_data = new FormData();
        const filename =  file.name;
        form_data.append("_file", file, filename);
        agros
            .post("root/UploadFile", form_data, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            })
            .then((res) => {
                setFile(res.data.url)
                setImage(res.data)
                onSuccess(null, file);
            })
            .catch((err) => onError());
    };

    const getLanguage = async () => {
        await agros.get('languages').then((res) => {
            setLangs(res.data)
        });
    }

    const setEditingObject = async (i) => {
        setEditing(i);
        await agros.get(`${mainUrl}/${i}`).then((res) => {
            setFile(res.data.image);
            setImage(res.data.image)
            setFileList([{
                uid: res.data.image,
                url: res.data.image.url,
            }]);
            let obj = {};
            // let objText = {};
            let datas = {}
            res.data.content.forEach((item) => {
                // obj[`body_${item.language}`] = item.content; // body text ola biler
                // objText[`text_${item.language}`] = item.content;
              
                obj[`body_${item.languageName}`] = item.text;
                datas[`body_${item.languageName}`] = item.text;
            });
            setText(datas)
            // setActive(names.is_active === 1)
            form.setFieldsValue(obj);
        });
    };

    const cancelEditing = () => {
        setEditing(null);
        setFile(null)
        setFileList([])
        setActive(false)
        setImage(null)
        setText({})
        form.resetFields();
    };



    const deletePosition = async (i) => {
        await agros
            .delete(`${mainUrl}/${i}`)
            .then(() => {
                // description
                notify("silindi", true);
                getPositions();
            })
            .catch((err) => {
                //error
                notify(err.response, false);
            });
    };

    const savePosition = async (values) => {
        let obj = {
            Image: image,
            Content: langs.map((l) => {
                return {
                    LanguageName: l.code,
                    Text: values[`body_${l.code}`]
                };
            }),
        };
        if (!editing) {
            await agros
                .post(mainUrl, obj)
                .then((res) => {
                    notify("", true);
                    getPositions();
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        } else {
            obj["id"] = editing;
            await agros
                .put(`${mainUrl}/${editing}`, obj)
                .then((res) => {
                    notify("", true);
                    getPositions();
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }
    };

    const getPositions = async () => {
        setSpin(true);
        await agros.get(mainUrl, { params: { per_page: 20000 } }).then((res) => {
            setSpin(false);
            setPositions(
                res.data.map((p, index) => {
                    return {
                        key: index + 1,
                        ...p,
                        index: index + 1,
                        // name: p.locales.find((l) => {
                        //     return l.local === localStorage.getItem("locale");
                        // }).name,
                    };
                })
            );
        });
    };


    useEffect(() => {
        getPositions();
        getLanguage();
    }, [t]);

    return (
        <Row gutter={[10, 10]}>
            <Col xs={24}>
                <div className="border animated fadeInDown p-2 mt-0 bg-white">
                    <UnorderedListOutlined className="f-20 mr5-15" />
                    <span className="f-20 bold">Haqqımızda</span>
                </div>
            </Col>
            <Col lg={12} xs={24}>
                <Table
                    loading={spin}
                    size="small"
                    className="bg-white animated fadeInLeft"
                    columns={columns}
                    dataSource={convertColumns(positions, cols)}
                    pagination={{
                        pageSize: 10,
                        current_page: 1,
                        total: positions.length,
                    }}
                />
            </Col>
            <Col lg={12} xs={24}>
                <Card title={t("addTo")} className={"animated fadeInRight"}>
                    <Form layout="vertical" onFinish={savePosition} form={form}>
                        <Form.Item
                            className={'animated fadeIn'}
                            validateTrigger="onChange"
                            name={`photo`}
                            required
                        >

                            <Upload
                                accept=".png, .jpg , .jpeg, .svg"
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onChange}
                                onPreview={handlePreview}
                                customRequest={setUploadFile}
                                beforeUpload={null}
                            >
                                {fileList.length < 1 && "+ Yüklə"}
                            </Upload>
                            {/* {/</ImgCrop>/} */}
                            <p className="f-10">500 x 400 px </p>
                        </Form.Item>



                        <p className="mb-5">Məlumat</p>

                        <Col xs={24}>
                            {/*<div className="mb-10">Kontent</div>*/}
                            {langs.map((l) => (
                                <div key={l.id} className="form-lang">
                                 
                                  
                                    <Form.Item
                                        name={`body_${l.code}`}
                                        validateTrigger="onChange"
                                        // getValueFromEvent={(event, editor) => {
                                        //     const dat = editor.getData();
                                        //     return dat;
                                        // }}
                                        rules={[noWhitespace(t("inputError"))]}
                                    >
                                        <JoditEditor
                                            onChange={(newContent) => {
                                                form.setFieldsValue({
                                                    [`text_${l.key}`]: newContent
                                                })
                                            }}
                                            className={'mb-15'}
                                            ref={editor}
                                            value={text[`text_${l.key}`]}
                                            tabIndex={1} // tabIndex of textarea
                                        />

                                    </Form.Item>
                                </div>
                            ))}
                        </Col>


                        <div className="flex  flex-between mt-15">
                            <Button onClick={cancelEditing}>{t("cancel")}</Button>
                            <Button htmlType="submit">{t("save")}</Button>
                        </div>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
};

const mapStateToProps = ({ langs }) => {
    return {
        langs: langs,
    };
};

export default connect(mapStateToProps, { notify })(History);