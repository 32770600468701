import React, { useState, useEffect } from "react";
import { Col, Row, Button, Card } from "antd";
import {
    PicCenterOutlined,
    MailOutlined,
    PhoneOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import agros from "../../../const/api";
import { useTranslation } from "react-i18next";
import { notify } from "../../../redux/actions";
import nodata from "../../../assets/img/nodata.png";

function Moduls() {
    let mainUrl = 'root/Modul'
    const [infos, setInfos] = useState({});
    const { t } = useTranslation();
    let [empty, setEmpty] = useState(false);
    const getInfos = () => {
        agros.get(mainUrl).then((res) => {
                setInfos(res.data)
        });
    };
    console.log(infos);
  


    useEffect(() => {
        getInfos();
    }, [t]);
    return (
        <div>
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <div className="border  flex-between pag  e-heading flex p-2 mt-0 bg-white">
                        <div className="page-name">
                            <PicCenterOutlined className="f-20 mr5-15" />
                            <span className="f-20 bold">Modullar</span>
                        </div>
                        <div className="flex flex-wrap gap-2">
                                    <div>
                                        <Link to={`/moduls/edit/${infos.id}`}>
                                            {" "}
                                            <Button type={"primary"}>{t("edit")}</Button>
                                        </Link>
                                    </div>
                        </div>
                    </div>
                </Col>
                {
                    empty ? (
                        <Col xs={24}>
                            <div className="border all-center pag  e-heading flex p-2 mt-0 bg-white "><img src={nodata} /></div>
                        </Col>
                    ) : (
                        <Col xs={24} className="border flex flex-wrap   pag  e-heading contact p-2 mt-0 bg-white">
                        <Col xs={24} className="flex">
                                <Col md={8}>
                                    <Card className={"animated fadeInLeft"}>
                                        <div className="mb-20">
                                            <div className="mb-2">
                                                <p className={"f-17"}>
                                                Default Istifadəçi sayı :  {infos?.user?.userCount}
                                                </p>
                                                <p className={"f-17"}>
                                                    Istifadəçi qiyməti :  {infos?.user?.userPrice} azn
                                                </p>
                                                <p className={"f-17"}>
                                                Əlavə istifadəçi qiyməti :  {infos?.user?.costUserPrice} azn
                                                </p>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col md={8}>
                                    <Card className={"animated fadeInLeft"}>
                                        <div className="mb-20">
                                            <div className="mb-2">
                                                <p className={"f-17"}>
                                                Default Yaddaş sayı :  {infos?.memory?.memoryCount}
                                                </p>
                                                <p className={"f-17"}>
                                                    Yaddaş qiyməti :  {infos?.memory?.memoryPrice} azn
                                                </p>
                                                <p className={"f-17"}>
                                                    Əlavə yaddaş qiyməti :  {infos?.memory?.costMemoryPrice} azn
                                                </p>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                        </Col>
                        <Col xs={24} className="flex">
                                <Col md={8}>
                                    <Card className={"animated fadeInLeft"}>
                                        <div className="mb-20">
                                            <div className="mb-3">
                                                <p className={"f-17 mb-5"}>
                                                    Modul adı :  {infos?.agriculture?.name}
                                                </p>
                                                <p className={"f-17 mb-5"}>
                                                    Haqqında :   <span
                                                        dangerouslySetInnerHTML={{
                                                            __html: infos?.agriculture?.description
                                                        }} />
                                                </p>
                                                <p className={"f-17 mb-5"}>
                                                    Qiymət :  {infos?.agriculture?.price} azn
                                                </p>
                                                <p className={"f-17 mb-5"}>
                                                    Görünməyi :  {infos?.agriculture?.hideShowStatus ? 'Görünür' : 'Gizlənir'}
                                                </p>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col md={8}>
                                    <Card className={"animated fadeInLeft"}>
                                        <div className="mb-20">
                                            <div className="mb-3">
                                                <p className={"f-17 mb-5"}>
                                                    Modul adı :  {infos?.vegetationIndex?.name}
                                                </p>
                                                <p className={"f-17 mb-5"}>
                                                    Haqqında :   <span
                                                        dangerouslySetInnerHTML={{
                                                            __html: infos?.vegetationIndex?.description
                                                        }} />
                                                </p>
                                                <p className={"f-17 mb-5"}>
                                                    Qiymət :  {infos?.vegetationIndex?.price} azn
                                                </p>
                                                <p className={"f-17 mb-5"}>
                                                    Görünməyi :  {infos?.vegetationIndex?.hideShowStatus ? 'Görünür' : 'Gizlənir'}
                                                </p>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col md={8}>
                                    <Card className={"animated fadeInLeft"}>
                                        <div className="mb-20">
                                            <div className="mb-3">
                                                <p className={"f-17 mb-5"}>
                                                    Modul adı :  {infos?.diseasesRisks?.name}
                                                </p>
                                                <p className={"f-17 mb-5"}>
                                                    Haqqında :   <span
                                                        dangerouslySetInnerHTML={{
                                                            __html: infos?.diseasesRisks?.description
                                                        }} />
                                                </p>
                                                <p className={"f-17 mb-5"}>
                                                    Qiymət :  {infos?.diseasesRisks?.price} azn
                                                </p>
                                                <p className={"f-17 mb-5"}>
                                                    Görünməyi :  {infos?.diseasesRisks?.hideShowStatus ? 'Görünür' : 'Gizlənir'}
                                                </p>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                        </Col>
                        </Col>
                        
                    )
                }

            </Row>
        </div>
    );
}

export default Moduls;