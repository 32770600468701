import React, { Component } from "react";
import { connect } from "react-redux";
import { notify } from "../../../redux/actions";
import { noWhitespace, whiteSpace } from "../../../utils/rules";
import { withTranslation } from "react-i18next";
import {
    Col,
    Input,
    Modal,
    Form,
    Row,
    Button,
    Switch,
    Select,
    InputNumber,
    Card
} from "antd";
import { PicCenterOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { agros } from "../../../const/api";
import JoditEditor from "jodit-react";

class EditModuls extends Component {
    formRef = React.createRef();
    editor = React.createRef();
    mainUrl = "root/Modul";
    id = this.props.match.params.id;
    constructor(props) {
        super(props);
    }

    state = {
        isMember: false,
        isMeeting: false,
        isRepresent: false,
        isDiscussion: false,
        aboutt: {},
        langs: [],
        isSeeFirst: false,
        isSeeSecond: false,
        isSeeThree: false,
        data: {},
        agri: true,
        demoOne: false,
        demoTwo: false,
        firstSubTitle: 1,
        secondSubTitle: 1,
        threeSubTitle: 1
    };

    componentDidMount() {
        let obj = {};
        const getPost = async () => {
            await agros.get(this.mainUrl + "/" + '1').then((res) => {
                this.setState({
                    isSeeFirst: res.data?.agriculture?.hideShowStatus,
                    isSeeSecond: res.data?.vegetationIndex?.hideShowStatus,
                    isSeeThree: res.data?.diseasesRisks?.hideShowStatus,
                });

                this.setState({
                    data: res.data
                })
                console.log(res.data);
                res.data.agriculture.content.forEach((d) => {
                    obj[`agriName_${d.languageName}`] = d.name;
                    obj[`agriBody_${d.languageName}`] = d.description;
                });
                res.data.vegetationIndex.content.forEach((d) => {
                    obj[`DemoOneName_${d.languageName}`] = d.name;
                    obj[`DemoOneBody_${d.languageName}`] = d.description;
                });
                obj["contentVegetation"] = res.data.vegetationIndex.content;
                obj["contentdiseasesRisks"] = res.data.diseasesRisks.content;
                obj["contentAgri"] = res.data.agriculture.content;
                this.setState({ secondSubTitle: res.data.vegetationIndex.content[0].vegetationIndexSubTitleContent.length });
                this.setState({ threeSubTitle: res.data.diseasesRisks.content[0].diseasesRisksSubTitleContent.length });
                this.setState({ firstSubTitle: res.data.agriculture.content[0].agricultureSubModulContent.length });
                res.data.diseasesRisks.content.forEach((d) => {
                    obj[`diseasesRisksName_${d.languageName}`] = d.name;
                    obj[`diseasesRisksBody_${d.languageName}`] = d.description;
                });
                obj["AgriPrice"] = res.data.agriculture.price;
                obj["vegetationIndexPrice"] = res.data.vegetationIndex.price;
                obj["diseasesRisksPrice"] = res.data.diseasesRisks.price;
                obj["userCount"] = res.data.user.userCount;
                obj["userPrice"] = res.data.user.userPrice;
                obj["memoryCount"] = res.data.memory.memoryCount;
                obj["memoryPrice"] = res.data.memory.memoryPrice;
                obj["costUserPrice"] = res.data.user.costUserPrice;
                obj["vegetationCount"] = res.data.vegetationIndex.count;
                obj["costVegetationPrice"] = res.data.vegetationIndex.costPrice;
                obj["diseasesRisksCount"] = res.data.diseasesRisks.count;
                obj["costDiseasesRisksPrice"] = res.data.diseasesRisks.costPrice;
                obj["costMemoryPrice"] = res.data.memory.costMemoryPrice;
                
                console.log(obj);
                // obj["TheRightToPostArticles"] = res.data.theRightToPostArticles;
                // obj["TakeAdvantageCertifications"] =
                //     res.data.takeAdvantageCertifications;
                // obj["FreeParticipationInTrainings"] =
                //     res.data.freeParticipationInTrainings;
                if (this.formRef.current !== null) {
                    this.formRef.current.setFieldsValue(obj);
                }
            });
        };

        getPost()
        const getLanguage = async () => {
            await agros.get('languages').then((res) => {
                this.setState({ langs: res.data });
            });
        }
        getLanguage();
    }

    saveItem = async (values) => {
        console.log(values);
        const data = (l,content,name) => {
            console.log(content);
            switch (l.code) {
            case "az":
                return values[content][0][name]
            case "en":
                    return values[content][1][name]
            case "ru":
                    return values[content][2][name]
            }

    }

        let obj = {
            id: this.props.match.params.id,
            user: {
                userCount: values.userCount,
                userPrice: values.userPrice,
                costUserPrice: values.costUserPrice
            },
            memory: {
                memoryCount: values.memoryCount,
                memoryPrice: values.memoryPrice,
                costMemoryPrice: values.costMemoryPrice
            },
            agriculture: {
                price: values.AgriPrice,
                hideShowStatus: this.state.isSeeFirst,
                content: this.state.langs.map((l) => {
                    console.log(l)
                    return {
                        languageName: l.code,
                        name: values[`agriName_${l.code}`],
                        description: values[`agriBody_${l.code}`],
                        agricultureSubModulContent: data(l,'contentAgri', 'agricultureSubModulContent')
                    };
                }),
            },
            vegetationIndex: {
                price: values.vegetationIndexPrice,
                hideShowStatus: this.state.isSeeSecond,
                count: values.vegetationCount,
                costPrice: values.costVegetationPrice,
                content: this.state.langs.map((l) => {
                    return {
                        languageName: l.code,
                        name: values[`DemoOneName_${l.code}`],
                        description: values[`DemoOneBody_${l.code}`],
                        vegetationIndexSubTitleContent: data(l,'contentVegetation', 'vegetationIndexSubTitleContent')
                    };
                }),
            },
            diseasesRisks: {
                price: values.diseasesRisksPrice,
                count: values.diseasesRisksCount,
                costPrice: values.costDiseasesRisksPrice,
                hideShowStatus: this.state.isSeeThree,
                content: this.state.langs.map((l) => {
                    return {
                        languageName: l.code,
                        name: values[`diseasesRisksName_${l.code}`],
                        description: values[`diseasesRisksBody_${l.code}`],
                        diseasesRisksSubTitleContent: data(l,'contentdiseasesRisks', 'diseasesRisksSubTitleContent')
                    };
                }),
            }
        };
        console.log(obj);
        await agros
            .put(`${this.mainUrl}/${this.props.match.params.id}`, obj)
            .then((res) => {
                this.props.notify("", true);
                this.formRef.current.resetFields();
                window.history.back();
            })
            .catch((err) => {
                this.errorGenerator(err.response.data.error);
            });
    }

    errorGenerator(error) {
        notify("Xəta baş verdi", false);
    }

    render() {
        let { t, getLangs, notify } = this.props;
        console.log(this.state.demoOne);
        return (
            <div>
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
                            <div className="page-name">
                                <PicCenterOutlined className="f-20 mr5-15" />
                                <span className="f-20 bold">
                                    Modulu redaktə et
                                </span>
                            </div>
                            <Link
                                to={{
                                    pathname: `/moduls`
                                }}
                            >
                                <Button type={"primary"}>{t("cancel")}</Button>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={24}>
                        <div className={`p-2 animated edit fadeInUp bg-white`}>
                            <Form
                                ref={this.formRef}
                                onFinish={this.saveItem}
                                layout="vertical"
                            >
                                <Row gutter={[55, 35]}>
                                    <Col md={8} sm={12} xs={24}>
                                        <p className={"mb-15"}>
                                            {" "}
                                            Default Istifadəçi sayı
                                        </p>
                                        <Form.Item
                                            validateTrigger="onChange"
                                            name={"userCount"}
                                            rules={[noWhitespace(t("inputError"))]}
                                        >
                                            <InputNumber className="w-100" />
                                        </Form.Item>
                                    </Col>
                                    <Col md={8} sm={12} xs={24}>
                                        <p className={"mb-15"}>
                                            {" "}
                                            Istifadəçi qiyməti
                                        </p>
                                        <div className="form-lang">
                                            <Form.Item
                                                validateTrigger="onChange"
                                                name={"userPrice"}
                                                rules={[noWhitespace(t("inputError"))]}
                                            >
                                                <InputNumber className="w-100" />

                                            </Form.Item>
                                            <div className="input-lang">azn</div>
                                        </div>
                                    </Col>
                                    <Col md={8} sm={12} xs={24}>
                                        <p className={"mb-15"}>
                                            {" "}
                                            Əlavə istifadəçi qiyməti
                                        </p>
                                        <div className="form-lang">
                                            <Form.Item
                                                validateTrigger="onChange"
                                                name={"costUserPrice"}
                                                rules={[noWhitespace(t("inputError"))]}
                                            >
                                                <InputNumber className="w-100" />

                                            </Form.Item>
                                            <div className="input-lang">azn</div>
                                        </div>
                                    </Col>
                                    <Col md={8} sm={12} xs={24}>
                                        <p className={"mb-15"}>
                                            {" "}
                                            Default Yaddaş sayı
                                        </p>
                                        <Form.Item
                                            validateTrigger="onChange"
                                            name={"memoryCount"}
                                            rules={[noWhitespace(t("inputError"))]}
                                        >
                                            <InputNumber className="w-100" />
                                        </Form.Item>
                                    </Col>
                                    <Col md={8} sm={12} xs={24}>
                                        <p className={"mb-15"}>
                                            {" "}
                                            Yaddaş qiyməti
                                        </p>
                                        <div className="form-lang">
                                            <Form.Item
                                                validateTrigger="onChange"
                                                name={"memoryPrice"}
                                                rules={[noWhitespace(t("inputError"))]}
                                            >
                                                <InputNumber className="w-100" />

                                            </Form.Item>
                                            <div className="input-lang">azn</div>
                                        </div>
                                    </Col>
                                    <Col md={8} sm={12} xs={24}>
                                        <p className={"mb-15"}>
                                            {" "}
                                            Əlavə yaddaş qiyməti
                                        </p>
                                        <div className="form-lang">
                                            <Form.Item
                                                validateTrigger="onChange"
                                                name={"costMemoryPrice"}
                                                rules={[noWhitespace(t("inputError"))]}
                                            >
                                                <InputNumber className="w-100" />

                                            </Form.Item>
                                            <div className="input-lang">azn</div>
                                        </div>
                                    </Col>
                                    {/* modul */}
                                    <Col md={4}>
                                        <Button className={"mr-15"} onClick={() => this.setState({ agri: true, demoOne: false, demoTwo: false })}>
                                            {this.state?.data?.agriculture?.content[0]?.name}
                                        </Button>
                                    </Col>
                                    <Col md={4}>
                                        <Button className={"mr-15"} onClick={() => this.setState({ demoOne: true, agri: false, demoTwo: false })}>
                                            {this.state?.data?.vegetationIndex?.content[0]?.name}
                                        </Button>
                                    </Col>
                                    <Col md={4}>
                                        <Button className={"mr-15"} onClick={() => this.setState({ demoTwo: true, agri: false, demoOne: false })}>
                                            {this.state?.data?.diseasesRisks?.content[0]?.name}
                                        </Button>
                                    </Col>
                                    <Card className={"animated fadeInLeft"} style={{ marginLeft: '25px', width: '58%', display: this.state.agri ? 'block' : 'none' }}>
                                        <Col xs={24}>
                                            <div>
                                                <div className="flex mb-15">
                                                    <Switch
                                                        className={"mr-15"}
                                                        checked={this.state.isSeeFirst}
                                                        onChange={() => {
                                                            this.setState({ isSeeFirst: !this.state.isSeeFirst });
                                                        }}
                                                    />
                                                    <span className="mr-15">
                                                        {" "}
                                                        {this.state?.data?.agriculture?.content[0]?.name} Modulunun görünməyi
                                                    </span>
                                                </div>
                                                <p className={"mb-15"}>
                                                    {" "}
                                                    Modul qiyməti
                                                </p>
                                                <div className="form-lang">
                                                    <Form.Item
                                                        validateTrigger="onChange"
                                                        name={"AgriPrice"}
                                                        rules={[noWhitespace(t("inputError"))]}
                                                    >
                                                        <InputNumber className="w-100" />

                                                    </Form.Item>
                                                    <div className="input-lang">azn</div>
                                                </div>
                                            </div>

                                        </Col>
                                        <Col xs={24}>
                                            <p className="mb-5">Modulun adı və məlumatı</p>
                                            {this.state.data?.agriculture?.content.map((l, index) => (
                                                <div key={index} className="form-lang mb-10 border p-2">

                                                    <div className="mb-10">
                                                        {this.state.langs[index]?.name}
                                                    </div>
                                                    <div className="form-lang">
                                                        <Form.Item
                                                            className="mb-10"
                                                            validateTrigger="onChange"
                                                            name={`agriName_${l.languageName}`}
                                                            rules={[whiteSpace(t("inputError"))]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                        <div className="input-lang">{l.languageName}</div>
                                                    </div>
                                                    <Form.Item
                                                        name={`agriBody_${l.languageName}`}
                                                        validateTrigger="onChange"
                                                        // getValueFromEvent={(event, editor) => {
                                                        //     const dat = editor.getData();
                                                        //     return dat;
                                                        // }}
                                                        //rules={[noWhitespace(t("inputError"))]}
                                                    >
                                                        {/* <TextArea
                                                        value={this.state.aboutt[`about_${l.key}`]}
                                                        ref={this.editor}
                                                        maxLength={250}
                                                    /> */}
                                                        <JoditEditor
                                                            ref={this.editor}
                                                            value={this.state.aboutt[`agriBody_${l.languageName}`]}
                                                            tabIndex={1} // tabIndex of textarea
                                                        />
                                                    </Form.Item>
                                                    {/* <div className="form-lang">
                                                            <Form.Item
                                                                className="mb-10"
                                                                validateTrigger="onChange"
                                                                name={`DemoOneName_${l.languageName}`}
                                                                rules={[whiteSpace(t("inputError"))]}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <div className="input-lang">{l.languageName}</div>
                                                        </div> */}
                                                    <div>
                                                        <p className={"mb-15 mt-10"}>
                                                            {" "}
                                                            Alt başlıqlar
                                                        </p>
                                                        {
                                                            [...Array(this.state.firstSubTitle)].map((_, mindex) => (
                                                                <Form.Item
                                                                    className={"mb-10"}
                                                                    validateTrigger="onSave"
                                                                    name={["contentAgri",index,"agricultureSubModulContent", mindex, `name`]}
                                                                    key={mindex}
                                                                   // rules={[noWhitespace(t("inputError"))]}
                                                                >
                                                                    <Input />
                                                                </Form.Item>
                                                            ))
                                                        }
                                                        <div>
                                                            <Button
                                                                onClick={() => this.setState({ firstSubTitle: this.state.firstSubTitle + 1 })}
                                                                className={"mr-10"}
                                                                type={"primary"}
                                                                shape={"circle"}
                                                                icon={<PlusOutlined />}
                                                            />
                                                            <Button
                                                                onClick={() => this.setState({ firstSubTitle: this.state.firstSubTitle - 1 })}
                                                                type={"primary"}
                                                                disabled={this.state.firstSubTitle === 1}
                                                                shape={"circle"}
                                                                icon={<MinusOutlined />}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/*      
                                                        <div className="form-lang">
                                                            <Form.Item
                                                                className="mb-10"
                                                                validateTrigger="onChange"
                                                                name={`DemoOneName_${l.code}`}
                                                                rules={[whiteSpace(t("inputError"))]}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <div className="input-lang">{l.code}</div>
                                                        </div> */}
                                                </div>
                                            ))}
                                        </Col>

                                    </Card>
                                    <Card className={"animated fadeInLeft"} style={{ marginLeft: '25px', width: '58%', display: this.state.demoOne ? 'block' : 'none' }}>
                                        <Col xs={24}>
                                            <div>
                                                <div className="flex mb-15">
                                                    <Switch
                                                        className={"mr-15"}
                                                        checked={this.state.isSeeSecond}
                                                        onChange={() => {
                                                            this.setState({ isSeeSecond: !this.state.isSeeSecond });
                                                        }}
                                                    />
                                                    <span className="mr-15">
                                                        {" "}
                                                        {this.state?.data?.vegetationIndex?.content[0]?.name} Modulunun görünməyi
                                                    </span>
                                                </div>
                                                {/* <p className={"mb-15"}>
                                                        {" "}
                                                        Modul qiyməti
                                                    </p>
                                                    <div className="form-lang">
                                                        <Form.Item
                                                            validateTrigger="onChange"
                                                            name={"vegetationIndexPrice"}
                                                            rules={[noWhitespace(t("inputError"))]}
                                                        >
                                                            <InputNumber className="w-100" />

                                                        </Form.Item>
                                                        <div className="input-lang">azn</div>
                                                    </div> */}
                                            </div>

                                        </Col>
                                        <Col xs={24} className="flex gap-10">
                                            <Col md={8} sm={12} xs={24} style={{ paddingLeft: '0' }}>
                                                <p className={"mb-15"}>
                                                    {" "}
                                                    Default Modul sayı
                                                </p>
                                                <Form.Item
                                                    validateTrigger="onChange"
                                                    name={"vegetationCount"}
                                                    rules={[noWhitespace(t("inputError"))]}
                                                >
                                                    <InputNumber className="w-100" />
                                                </Form.Item>
                                            </Col>
                                            <Col md={8} sm={12} xs={24}>
                                                <p className={"mb-15"}>
                                                    {" "}
                                                    Modul qiyməti
                                                </p>
                                                <div className="form-lang">
                                                    <Form.Item
                                                        validateTrigger="onChange"
                                                        name={"vegetationIndexPrice"}
                                                        rules={[noWhitespace(t("inputError"))]}
                                                    >
                                                        <InputNumber className="w-100" />

                                                    </Form.Item>
                                                    <div className="input-lang">azn</div>
                                                </div>
                                            </Col>
                                            <Col md={8} sm={12} xs={24}>
                                                <p className={"mb-15"}>
                                                    {" "}
                                                    Əlavə modul qiyməti
                                                </p>
                                                <div className="form-lang">
                                                    <Form.Item
                                                        validateTrigger="onChange"
                                                        name={"costVegetationPrice"}
                                                        rules={[noWhitespace(t("inputError"))]}
                                                    >
                                                        <InputNumber className="w-100" />

                                                    </Form.Item>
                                                    <div className="input-lang">azn</div>
                                                </div>
                                            </Col>
                                        </Col>
                                        <Col xs={24}>
                                            <p className="mb-5">Modulun adı və məlumatı</p>
                                            {this.state.data?.vegetationIndex?.content.map((l, index) => (
                                                <div key={index} className="form-lang mb-10 border p-2">

                                                    <div className="mb-10">
                                                        {this.state.langs[index]?.name}
                                                    </div>
                                                    <div className="form-lang">
                                                        <Form.Item
                                                            className="mb-10"
                                                            validateTrigger="onChange"
                                                            name={`DemoOneName_${l.languageName}`}
                                                            rules={[whiteSpace(t("inputError"))]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                        <div className="input-lang">{l.languageName}</div>
                                                    </div>
                                                    <Form.Item
                                                        name={`DemoOneBody_${l.languageName}`}
                                                        validateTrigger="onChange"
                                                        // getValueFromEvent={(event, editor) => {
                                                        //     const dat = editor.getData();
                                                        //     return dat;
                                                        // }}
                                                        //rules={[noWhitespace(t("inputError"))]}
                                                    >
                                                        {/* <TextArea
                                                        value={this.state.aboutt[`about_${l.key}`]}
                                                        ref={this.editor}
                                                        maxLength={250}
                                                    /> */}
                                                        <JoditEditor
                                                            ref={this.editor}
                                                            value={this.state.aboutt[`DemoOneAbout_${l.languageName}`]}
                                                            tabIndex={1} // tabIndex of textarea
                                                        />
                                                    </Form.Item>
                                                    {/* <div className="form-lang">
                                                            <Form.Item
                                                                className="mb-10"
                                                                validateTrigger="onChange"
                                                                name={`DemoOneName_${l.languageName}`}
                                                                rules={[whiteSpace(t("inputError"))]}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <div className="input-lang">{l.languageName}</div>
                                                        </div> */}
                                                    <div>
                                                        <p className={"mb-15 mt-10"}>
                                                            {" "}
                                                            Alt başlıqlar
                                                        </p>
                                                        {
                                                            [...Array(this.state.secondSubTitle)].map((_, mindex) => (
                                                                <Form.Item
                                                                    className={"mb-10"}
                                                                    validateTrigger="onSave"
                                                                    name={["contentVegetation",index,"vegetationIndexSubTitleContent", mindex, `name`]}
                                                                    key={mindex}
                                                                   // rules={[noWhitespace(t("inputError"))]}
                                                                >
                                                                    <Input />
                                                                </Form.Item>
                                                            ))
                                                        }
                                                        <div>
                                                            <Button
                                                                onClick={() => this.setState({ secondSubTitle: this.state.secondSubTitle + 1 })}
                                                                className={"mr-10"}
                                                                type={"primary"}
                                                                shape={"circle"}
                                                                icon={<PlusOutlined />}
                                                            />
                                                            <Button
                                                                onClick={() => this.setState({ secondSubTitle: this.state.secondSubTitle - 1 })}
                                                                type={"primary"}
                                                                disabled={this.state.secondSubTitle === 1}
                                                                shape={"circle"}
                                                                icon={<MinusOutlined />}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/*      
                                                        <div className="form-lang">
                                                            <Form.Item
                                                                className="mb-10"
                                                                validateTrigger="onChange"
                                                                name={`DemoOneName_${l.code}`}
                                                                rules={[whiteSpace(t("inputError"))]}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <div className="input-lang">{l.code}</div>
                                                        </div> */}
                                                </div>
                                            ))}
                                        </Col>

                                    </Card>
                                    <Card className={"animated fadeInLeft"} style={{ marginLeft: '25px', width: '58%', display: this.state.demoTwo ? 'block' : 'none' }}>
                                        <Col xs={24}>
                                            <div>
                                                <div className="flex mb-15">
                                                    <Switch
                                                        className={"mr-15"}
                                                        checked={this.state.isSeeThree}
                                                        onChange={() => {
                                                            this.setState({ isSeeThree: !this.state.isSeeThree });
                                                        }}
                                                    />
                                                    <span className="mr-15">
                                                        {" "}
                                                        {this.state?.data?.diseasesRisks?.content[0]?.name} Modulunun görünməyi
                                                    </span>
                                                </div>
                                                {/* <p className={"mb-15"}>
                                                        {" "}
                                                        Modul qiyməti
                                                    </p>
                                                    <div className="form-lang">
                                                        <Form.Item
                                                            validateTrigger="onChange"
                                                            name={"DemoTwoPrice"}
                                                            rules={[noWhitespace(t("inputError"))]}
                                                        >
                                                            <InputNumber className="w-100" />

                                                        </Form.Item>
                                                        <div className="input-lang">azn</div>
                                                    </div> */}
                                            </div>

                                        </Col>
                                        <Col xs={24} className="flex gap-10">
                                            <Col md={8} sm={12} xs={24} style={{ paddingLeft: '0' }}>
                                                <p className={"mb-15"}>
                                                    {" "}
                                                    Default Modul sayı
                                                </p>
                                                <Form.Item
                                                    validateTrigger="onChange"
                                                    name={"diseasesRisksCount"}
                                                    rules={[noWhitespace(t("inputError"))]}
                                                >
                                                    <InputNumber className="w-100" />
                                                </Form.Item>
                                            </Col>
                                            <Col md={8} sm={12} xs={24}>
                                                <p className={"mb-15"}>
                                                    {" "}
                                                    Modul qiyməti
                                                </p>
                                                <div className="form-lang">
                                                    <Form.Item
                                                        validateTrigger="onChange"
                                                        name={"diseasesRisksPrice"}
                                                        rules={[noWhitespace(t("inputError"))]}
                                                    >
                                                        <InputNumber className="w-100" />

                                                    </Form.Item>
                                                    <div className="input-lang">azn</div>
                                                </div>
                                            </Col>
                                            <Col md={8} sm={12} xs={24}>
                                                <p className={"mb-15"}>
                                                    {" "}
                                                    Əlavə modul qiyməti
                                                </p>
                                                <div className="form-lang">
                                                    <Form.Item
                                                        validateTrigger="onChange"
                                                        name={"costDiseasesRisksPrice"}
                                                        rules={[noWhitespace(t("inputError"))]}
                                                    >
                                                        <InputNumber className="w-100" />

                                                    </Form.Item>
                                                    <div className="input-lang">azn</div>
                                                </div>
                                            </Col>
                                        </Col>
                                        <Col xs={24}>
                                            <p className="mb-5">Modulun adı və məlumatı</p>
                                            {this.state.data?.diseasesRisks?.content.map((l, index) => (
                                                <div key={index} className="form-lang mb-10 border p-2">

                                                    <div className="mb-10">
                                                        {this.state.langs[index]?.name}
                                                    </div>
                                                    <div className="form-lang">
                                                        <Form.Item
                                                            className="mb-10"
                                                            validateTrigger="onChange"
                                                            name={`diseasesRisksName_${l.languageName}`}
                                                            rules={[whiteSpace(t("inputError"))]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                        <div className="input-lang">{l.languageName}</div>
                                                    </div>
                                                    <Form.Item
                                                        name={`diseasesRisksBody_${l.languageName}`}
                                                        validateTrigger="onChange"
                                                        // getValueFromEvent={(event, editor) => {
                                                        //     const dat = editor.getData();
                                                        //     return dat;
                                                        // }}
                                                       // rules={[noWhitespace(t("inputError"))]}
                                                    >
                                                        {/* <TextArea
                                                        value={this.state.aboutt[`about_${l.key}`]}
                                                        ref={this.editor}
                                                        maxLength={250}
                                                    /> */}
                                                        <JoditEditor
                                                            ref={this.editor}
                                                            value={this.state.aboutt[`diseasesRisksAbout_${l.languageName}`]}
                                                            tabIndex={1} // tabIndex of textarea
                                                        />
                                                    </Form.Item>
                                                    {/* <div className="form-lang">
                                                            <Form.Item
                                                                className="mb-10"
                                                                validateTrigger="onChange"
                                                                name={`DemoOneName_${l.languageName}`}
                                                                rules={[whiteSpace(t("inputError"))]}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <div className="input-lang">{l.languageName}</div>
                                                        </div> */}
                                                    <div>
                                                        <p className={"mb-15 mt-10"}>
                                                            {" "}
                                                            Alt başlıqlar
                                                        </p>
                                                        {
                                                            [...Array(this.state.threeSubTitle)].map((_, mindex) => (

                                                                <Form.Item
                                                                    className={"mb-10"}
                                                                    validateTrigger="onSave"
                                                                    name={ ["contentdiseasesRisks",index,"diseasesRisksSubTitleContent", mindex, `name`]}
                                                                    key={mindex}
                                                                  //  rules={[noWhitespace(t("inputError"))]}
                                                                >
                                                                    <Input />
                                                                </Form.Item>
                                                            ))
                                                        }
                                                        <div>
                                                            <Button
                                                                onClick={() => this.setState({ threeSubTitle: this.state.threeSubTitle + 1 })}
                                                                className={"mr-10"}
                                                                type={"primary"}
                                                                shape={"circle"}
                                                                icon={<PlusOutlined />}
                                                            />
                                                            <Button
                                                                onClick={() => this.setState({ threeSubTitle: this.state.threeSubTitle - 1 })}
                                                                type={"primary"}
                                                                disabled={this.state.threeSubTitle === 1}
                                                                shape={"circle"}
                                                                icon={<MinusOutlined />}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/*      
                                                        <div className="form-lang">
                                                            <Form.Item
                                                                className="mb-10"
                                                                validateTrigger="onChange"
                                                                name={`DemoOneName_${l.code}`}
                                                                rules={[whiteSpace(t("inputError"))]}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <div className="input-lang">{l.code}</div>
                                                        </div> */}
                                                </div>
                                            ))}
                                        </Col>

                                    </Card>
                                    <Col xs={24}>
                                        <div className={"flex"}>
                                            <Button className={"mr-15"} htmlType="submit">
                                                {t("save")}
                                            </Button>
                                            <Link
                                                to={{
                                                    pathname: `/individual-registration`
                                                }}
                                            >
                                                <Button type={"primary"}>{t("cancel")}</Button>
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </Row>

                <Modal
                    visible={this.state.previewVisible}
                    title={false}
                    footer={null}
                    onCancel={() => {
                        this.setState({ previewVisible: false });
                    }}
                >
                    <img
                        alt="example"
                        style={{ width: "100%" }}
                        src={this.state.previewImage}
                    />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = ({ langs }) => {
    return {
        langs: langs,
    };
};

const exp = withTranslation()(EditModuls);
export default connect(mapStateToProps, { notify })(exp);
