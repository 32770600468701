import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  AuditOutlined,
  PictureOutlined,
  ContactsOutlined,
  HomeOutlined,
  HistoryOutlined,
  ShoppingOutlined ,
  ProjectOutlined
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { SubMenu } = Menu;

const MenuList = () => {
  const { t } = useTranslation();
  const [openKeys, setOpenKeys] = useState([]);

  const rootSubmenuKeys = ["11", "21", "31", "41", "51", "61", "71", "81", "91", "101"];


  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOpenChange = (openKeysList) => {
    const latestOpenKey = openKeysList.find(
      (key) => openKeys.indexOf(key) === -1
    );
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(openKeysList);
    } else {
      const opens = latestOpenKey ? [latestOpenKey] : [];
      setOpenKeys(opens);
    }
  };

  return (
    <Menu
      openKeys={openKeys}
      // inlineCollapsed={collapsed}
      mode="inline"
      theme="light"
      onOpenChange={onOpenChange}
      className="menu-ul"
    >
     
     <Menu.Item key="86">
          <Link to={`/about`}>
          <span>
          <HomeOutlined />
            <span >Ana səhifə</span>
          </span>
            
          </Link>
        </Menu.Item>
        <Menu.Item key="83">
          <Link to={`/products`}>
          <span>
          <ProjectOutlined />
          <span >Məhsullar</span>
          </span>
            
          </Link>
        </Menu.Item>
        <Menu.Item key="84">
          <Link to={`/history`}>
          <span>
          <HistoryOutlined />
          <span >Haqqımızda</span>
          </span>
            
          </Link>
        </Menu.Item>
        <Menu.Item key="85">
          <Link to={`/moduls`}>
          <span>
          <ShoppingOutlined />
            <span >Paketlər</span>
          </span>
          </Link>
        </Menu.Item>
        
    </Menu>
  );
};

const mapStateToProps = ({ user, stock }) => {
  return {
    // perms: user.data.userPermissions, stock 
  };
};

export default connect(mapStateToProps, {})(MenuList);


